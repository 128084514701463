import React from "react";
import objectPath from "object-path";
import { connect } from "react-redux";
import { autobind } from "react-decoration";
import qs from "querystring";
import { withRouter } from "next/router";
import { NextSeo } from "next-seo";
import Router from "next/router";
import classnames from "classnames";
import { action } from "@/modules";
import css from "./login.v2.module.scss";
import * as api from "@/api";

@withRouter
@connect(
	state => ({
		user: state.base.user
	}),
	dispatch => ({
		setUser: (user, access_token) => dispatch(action.base.setUser(user, access_token))
	})
)
class Login extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loaded: true,
			loading: false,
			payload: {
				username: "",
				password: ""
			},
			isOMSLogin: false,
		};
	}

	handleChange = path => value => {
		const state = { ... this.state };
		objectPath.set(state, path, value);
		this.setState(state);
	};

	@autobind
	async login() {
		const { username, password } = this.state.payload;

		if (username.trim() === "" || password.trim() === "")
			return window.alert("Username and password can't be empty");

		this.setState({ loading: true });
		try {
			const data = await api.user.login(username, password);
			this.props.setUser(data.user, data.access_token);
			localStorage.setItem(process.env.NEXT_PUBLIC_ACCESS_TOKEN_KEY, data.access_token);
			return Router.replace("/listing");
		} catch (error) {
			console.error(error);
			window.alert(error?.response?.data?.error?.message ?? "Invalid Username or Password");
			return this.setState({ loading: false });
		}
	}

	@autobind
	async loginViaIDP() {

		const origin = window.location.origin;

		const queries = {
			identity_provider: "ic-users",
			redirect_uri: origin,
			response_type: "TOKEN",
			client_id: "74fpihai9q27326khmajg13caq",
			scope: "aws.cognito.signin.user.admin email openid phone profile"
		};

		window
			.location
			.replace(
				"https://ic.auth.us-west-2.amazoncognito.com/oauth2/authorize?" +
				qs.stringify(queries)
			);
	}

	render() {

		const { loading, isOMSLogin, payload } = this.state;

		return (
			<>
				<NextSeo title="Login" />
				<div className={classnames(css.bg)} />
				<div className={classnames(css.overlay)} />
				<div className={classnames(css.container)}>
					<div className={classnames(css[isOMSLogin ? "left-portal" : "full-portal"])}>
						<div>
							<img
								src="https://images.squarespace-cdn.com/content/v1/65c6678d1492d524837de642/ca5f4381-a50d-468b-9826-415bafc4b176/InfiniteCommerce-Logo-White-H.png"
								alt="IC Logo"
								width={250}
							/>
							<div className={css.title}>
								<h3> Listing Management </h3>
								<span>Login using Microsoft 365 account to get quick access</span>
							</div>
							<button
								type="button"
								className={
									classnames(css["idp-button"], "btn", "btn-sm")
								}
								disabled={loading}
								onClick={() => this.loginViaIDP()}
							>
								<img src="/outlook.png" alt="Outlook" />
								<span> Login with Microsoft 365 </span>
							</button>
							{
								!isOMSLogin && (
									<a
										onClick={() => this.setState({ isOMSLogin: true })}
										className={classnames(css["legacy-login"])}
									>
										Use legacy OMS account login
									</a>
								)
							}
						</div>
					</div>
					{
						isOMSLogin && (
							<div className={classnames(css["right-portal"])}>

								<div>
									<span className="meta display-block margin-bottom-14"> Login to your account </span>
								</div>

								<form
									onSubmit={event => {
										event.preventDefault();
										this.login();
									}}
								>
									<input
										className={css["line-input"]}
										placeholder="Email address or User Id"
										value={payload.username}
										onChange={event => this.handleChange("payload.username")(event.target.value)}
									/>
									<input
										type="password"
										className={css["line-input"]}
										placeholder="Password"
										value={payload.password}
										onChange={event => this.handleChange("payload.password")(event.target.value)}
									/>
									<div className={css["forget-password"]}>
										<a
											href="https://cms.juvoplus.com/password/forget"
											target="_blank"
										>
											Forgot password?
										</a>
									</div>
									<button
										className={
											classnames("btn", "btn-sm", "btn-success", css["btn-login"])
										}
										type="submit"
										disabled={loading}
									>
										<i className="fa fa-circle-o-notch" />
										<span> OMS Login </span>
									</button>
								</form>
							</div>
						)
					}
				</div>
			</>
		);
	}

}

export default Login;
